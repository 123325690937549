import { AxiosPromise } from 'axios';
import axiosInstance from '@/helpers/axios.config';

interface GiftCardCredited {
  clientName: string;
  clientCategory: string;
  credit: number | null;
  validityStartDate: Date;
  validityEndDate: Date;
}

class GiftCardService {
  protected get baseUrl(): string {
    return `${process.env.VUE_APP_API_URL}/gift-cards`;
  }

  public checkValidity(id: string | null): Promise<AxiosPromise> {
    return axiosInstance.get(`${this.baseUrl}/${id}/validity`);
  }

  public burn(
    id: string | null,
    burnAmount: number,
  ): Promise<AxiosPromise> {
    return axiosInstance.patch(`${this.baseUrl}/${id}/burn`, {
      burnAmount,
    });
  }

  public getContractList(isContractAccepted: boolean): Promise<AxiosPromise> {
    return axiosInstance.get(
      `${this.baseUrl}-batches/contracts?isContractAccepted=${isContractAccepted}`,
    );
  }

  public getOneContract(batchId: string): Promise<AxiosPromise> {
    return axiosInstance.get(`${this.baseUrl}-batches/contract-to-sign/${batchId}`);
  }

  public validContract(idContract: string): Promise<AxiosPromise> {
    return axiosInstance.patch(`${this.baseUrl}-batches/contracts/${idContract}`);
  }

  public creditGiftCard(id: string, body: GiftCardCredited): Promise<AxiosPromise> {
    return axiosInstance.patch(`${this.baseUrl}/${id}/reload`, body);
  }

  public getListClientCategories(): Promise<AxiosPromise> {
    return axiosInstance.get(`${this.baseUrl}/client-categories/keys`);
  }
  public getListClientCategoriesValues(): Promise<AxiosPromise> {
    return axiosInstance.get(`${this.baseUrl}/client-categories/values`);
  }

  public exportGiftCardsCreditedToCsv(): Promise<AxiosPromise> {
    return axiosInstance.get(`${this.baseUrl}/history-cards-credited`, {
      responseType: 'blob',
    });
  }
}

export default new GiftCardService();
