
import { Component, Vue } from 'vue-property-decorator';
import SuccessCheck from '@/components/SuccessCheck.vue';
import giftCardService from '@/services/giftCards/giftCardService';
import userService from "@/services/users/userService";
import IContracts from './interfaces/Contract/contract';
import ICurrentUser from './interfaces/Contract/currentUser';
import moment from 'moment';
import authModule from "@/store/auth/auth.module";

@Component({
  components: {
    SuccessCheck,
  },
})
export default class ContractLotCard extends Vue {
  public userInfo = {};
  public contract = {};
  public isAcceptedContract = false;
  public checkedContract = false;
  public error = '';
  public loading = false;

  mounted(): void {
    // this.infoContrat();
  }

  async created(): Promise<void> {
  if (this.$route.query.contract) {
    this.loading = true;
    try {
      this.loadUser();
      if (typeof this.$route.query.contract === 'string') {
        this.contract = JSON.parse(this.$route.query.contract);
      }
    } catch (error: any) {
      const { data } = error.response;
      this.error = Array.isArray(data.message) ? data.message[0] : data.message;
    } finally {
      this.loading = false;
    }
  } else if (this.$route.query.id) {
    this.loading = true;
    try {
      this.loadUser();
      const id = Array.isArray(this.$route.query.id) ? this.$route.query.id[0] : this.$route.query.id;
      if(id !== null) {
        const { data } = await giftCardService.getOneContract(id);
        this.contract = data;
        // this.userInfo = data.contractAcceptation[0].user;
      }
    } catch (error: any) {
      const { data } = error.response;
      this.error = Array.isArray(data.message) ? data.message[0] : data.message;
    } finally {
      this.loading = false;
    }
  }
}

  async loadUser(): Promise<void> {
    const userId = this.$store.getters['authModule/userId'];
    const { data } = await userService.findOne(userId);
    this.userInfo = data;
  }

  // async infoContrat(): Promise<void> {
  //   this.loading = true;
  //   try {
  //     await this.getUserInfo(data);
  //     await this.getContractCommission(data);
  //   } catch (error) {
  //     const { data } = error.response;
  //     this.error = Array.isArray(data.message) ? data.message[0] : data.message;
  //   } finally {
  //     this.loading = false;
  //   }
  // }

  // async getUserInfo(data: { currentUser: ICurrentUser, contracts: IContracts[]}): Promise<void> {
  //   this.userInfo = data.currentUser;
  // }
  //
  // async getContractCommission(data: { currentUser: ICurrentUser, contracts: IContracts[]}): Promise<void> {
  //   this.contract = data.contracts.find((contract) => contract._id === this.$route.query.id);
  // }

  formatDate(date: string): string {
    return moment(date).format('DD MMMM Y').toString();
  }

  goToBottom() {
  const element = document.getElementById('check-contract');
  if (element !== null) {
    window.scrollTo({
      top: element.offsetTop - 300,
      left: 0,
      behavior: 'smooth',
      });
    }
  }

  async submitContract(): Promise<void> {
    this.loading = true;
    try {
      const id = Array.isArray(this.$route.query.id) ? this.$route.query.id[0] : this.$route.query.id;
      if (typeof id === 'string') {
        await giftCardService.validContract(id);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.isAcceptedContract = true;
      }
    } catch (error: any) {
        const { data } = error.response;
        this.error = Array.isArray(data.message) ? data.message[0] : data.message;
    } finally {
        this.loading = false;
    }
    }
  }
