
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SuccessCheck extends Vue {
  public show = false;

  created(): void {
    this.show = true;
  }
}
