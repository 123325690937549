import { AxiosPromise } from 'axios';
import axiosInstance from '@/helpers/axios.config';
import { UpdateUserDto } from './dto';

class UserService {
  protected get baseUrl(): string {
    return `${process.env.VUE_APP_API_URL}/users`;
  }

  update(id: string, updateUserDto: UpdateUserDto): Promise<AxiosPromise> {
    return axiosInstance.patch(`${this.baseUrl}/business-owner/${id}`, {
      ...updateUserDto,
      category: updateUserDto.category,
    });
  }

  findOne(id: string): Promise<AxiosPromise> {
    return axiosInstance.get(`${this.baseUrl}/${id}`);
  }
}

export default new UserService();
